import './Footer.css'

const Footer = () => (
    <footer className='footer'>
        {
            // <a
            // href=''
            // className='link footer__link'
            // >
            // </a>
        }
    </footer>
)

export default Footer
